/*!
 * tui-chart
 * @fileoverview tui-chart
 * @author NHN. FE Development Lab <dl_javascript@nhn.com>
 * @version 3.11.3
 * @license MIT
 * @link https://github.com/nhn/tui.chart
 * bundle created at "Fri Jan 29 2021 15:51:40 GMT+0900 (Korean Standard Time)"
 */
.tui-chart {
  position: relative;
  font-family: Arial;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart,
.tui-chart * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tui-chart svg line,
.tui-chart svg path,
.tui-chart svg rect {
  shape-rendering: crispEdges;
}
.tui-chart svg .auto-shape-rendering {
  shape-rendering: auto;
}
.tui-chart svg .stroke-dasharray {
  stroke-dasharray: 4;
}
.tui-chart .tui-chart-icon {
  background-color: '#f4f4f4';
}
.tui-chart .tui-chart-icon:hover {
  background-color: '#eeeeee';
}
.tui-chart .tui-chart-icon:active {
  background-color: '#e5e5e5';
}
.tui-chart .tui-chart-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  z-index: 350;
}
.tui-chart .tui-chart-axis-area {
  z-index: 300;
  position: absolute;
}
.tui-chart .tui-chart-axis-area * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart .tui-chart-axis-area .tui-chart-title-area {
  position: absolute;
}
.tui-chart .tui-chart-axis-area .tui-chart-tick-area,
.tui-chart .tui-chart-axis-area .tui-chart-label-area {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.tui-chart .tui-chart-axis-area .tui-chart-tick-area .tui-chart-tick {
  position: absolute;
  background-color: #000;
}
.tui-chart .tui-chart-axis-area .tui-chart-label-area .tui-chart-label {
  position: absolute;
}
.tui-chart .tui-chart-axis-area .tui-chart-label-area .tui-chart-label > span {
  line-height: 1.2;
}
.tui-chart .tui-chart-axis-area.vertical {
  top: 10px;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-title-area {
  text-align: center;
  white-space: nowrap;
  top: 0;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-title-area.rotation {
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  -o-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-tick-area {
  right: 0;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-tick-area .tui-chart-tick {
  right: 1px;
  width: 5px;
  height: 1px;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-tick-area .tui-chart-tick-line {
  left: auto;
  right: 0;
  width: 1px;
  background-color: #000;
  position: absolute;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-label-area {
  right: 10px;
}
.tui-chart .tui-chart-axis-area.vertical .tui-chart-label-area .tui-chart-label {
  left: 0;
  width: 100%;
  text-align: right;
  white-space: nowrap;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-title-area {
  text-align: center;
  white-space: nowrap;
  top: 0;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-title-area.rotation {
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  -o-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-tick-area,
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-label-area {
  left: 0;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-label-area .tui-chart-label {
  text-align: left;
  padding-right: 0;
  padding-left: 10px;
}
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-title-area {
  width: 100% !important;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  filter: none;
  top: auto;
}
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-label-area {
  left: 0;
  width: 100% !important;
}
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-label-area .tui-chart-label {
  text-align: center;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-tick-area,
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-tick-area.opposite-side {
  border-right: none;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-tick-area .tui-chart-tick,
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-tick-area.opposite-side .tui-chart-tick {
  left: 1px;
}
.tui-chart .tui-chart-axis-area.vertical.right .tui-chart-tick-area .tui-chart-tick-line,
.tui-chart .tui-chart-axis-area.vertical.center .tui-chart-tick-area.opposite-side .tui-chart-tick-line {
  right: auto;
  left: 0;
}
.tui-chart .tui-chart-axis-area.horizontal {
  right: 10px;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-title-area {
  bottom: 0;
  width: 100%;
  text-align: center;
}
.tui-chart .tui-chart-axis-area.horizontal.division .tui-chart-title-area {
  left: 0;
  width: auto;
}
.tui-chart .tui-chart-axis-area.horizontal.division .tui-chart-title-area.right {
  left: auto;
  right: 0;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-tick-area,
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area {
  left: 0;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-tick-area .tui-chart-tick-line {
  top: 0;
  height: 1px;
  background-color: #000;
  position: absolute;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-tick-area .tui-chart-ticks {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-tick-area .tui-chart-tick {
  top: 0;
  width: 1px;
  height: 6px;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label {
  top: 10px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation {
  text-align: right;
  white-space: nowrap;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation span {
  position: absolute;
  right: 0;
  top: 0;
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation25 {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation25 span {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.9063077870366499, M12=0.42261826174069944, M21=-0.42261826174069944, M22=0.9063077870366499)";
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation45 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation45 span {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.7071067811865476, M12=0.7071067811865475, M21=-0.7071067811865475, M22=0.7071067811865476)";
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation65 {
  -webkit-transform: rotate(-65deg);
  -moz-transform: rotate(-65deg);
  -ms-transform: rotate(-65deg);
  -o-transform: rotate(-65deg);
  transform: rotate(-65deg);
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation65 span {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.42261826174069944, M12=0.9063077870366499, M21=-0.9063077870366499, M22=0.42261826174069944)";
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation85 {
  -webkit-transform: rotate(-85deg);
  -moz-transform: rotate(-85deg);
  -ms-transform: rotate(-85deg);
  -o-transform: rotate(-85deg);
  transform: rotate(-85deg);
}
.tui-chart .tui-chart-axis-area.horizontal .tui-chart-label-area .tui-chart-label.tui-chart-xaxis-rotation85 span {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.08715574274765814, M12=0.9961946980917455, M21=-0.9961946980917455, M22=0.08715574274765814)";
}
.tui-chart .tui-chart-plot-area {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: -100;
}
.tui-chart .tui-chart-plot-area .tui-chart-plot-optional-lines-area {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.tui-chart .tui-chart-plot-area .tui-chart-plot-lines-area {
  width: 100%;
  height: 100%;
  position: relative;
}
.tui-chart .tui-chart-plot-area .tui-chart-plot-line {
  background-color: #ccc;
  position: absolute;
}
.tui-chart .tui-chart-plot-area .tui-chart-plot-line.vertical {
  top: 0px;
  width: 1px;
}
.tui-chart .tui-chart-plot-area .tui-chart-plot-line.horizontal {
  left: 0px;
  height: 1px;
}
.tui-chart .tui-chart-series-area {
  z-index: 200;
  position: absolute;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart .tui-chart-series-area:first-child {
  overflow: visible;
}
.tui-chart .tui-chart-series-area .tui-chart-series-block-area {
  position: absolute;
  left: 10px;
  top: 10px;
}
.tui-chart .tui-chart-series-area .tui-chart-series-block-area .tui-chart-series-block {
  position: absolute;
}
.tui-chart .tui-chart-series-area .tui-chart-series-label-area {
  position: absolute;
  overflow: visible;
  left: 0px;
  top: 0px;
  display: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}
.tui-chart .tui-chart-series-area .tui-chart-series-label-area .tui-chart-series-label {
  position: absolute;
  cursor: default;
  text-align: center;
  white-space: nowrap;
  text-shadow: #fff 0px 0px 3px;
}
.tui-chart .tui-chart-series-area .tui-chart-series-label-area.show {
  display: block;
}
.tui-chart .tui-chart-series-area .tui-chart-series-label-area.opacity {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}
.tui-chart .tui-chart-series-area .tui-chart-series-graph-area {
  position: absolute;
  left: 0;
  top: 0;
}
.tui-chart .tui-chart-zoom-area {
  z-index: 1500;
  position: absolute;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background-clip: padding-box;
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn {
  width: 24px;
  height: 24px;
  display: block;
  background-color: #f4f4f4;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 5px;
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-in {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEX19fX19fVVVVX09PSSNEQ7AAAAAnRSTlO0tU0x4YcAAABCSURBVAjXY6hh+AOGb4D4/weGXx9AJBwhuP//Q/BvKIbx//9FYgPxeyheBaX/Y2A81L/9YGrVKmQelMKioR5E/gEA8IGRuVetr4kAAAAASUVORK5CYII=);
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-in:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXu7u7u7u5VVVXu7u6LRCi7AAAAAnRSTlO0tU0x4YcAAABCSURBVAjXY6hh+AOGb4D4/weGXx9AJBwhuP//Q/BvKIbx//9FYgPxeyheBaX/Y2A81L/9YGrVKmQelMKioR5E/gEA8IGRuVetr4kAAAAASUVORK5CYII=);
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-in:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXm5ubm5uZVVVXl5eUH/eecAAAAAnRSTlO0tU0x4YcAAABCSURBVAjXY6hh+AOGb4D4/weGXx9AJBwhuP//Q/BvKIbx//9FYgPxeyheBaX/Y2A81L/9YGrVKmQelMKioR5E/gEA8IGRuVetr4kAAAAASUVORK5CYII=);
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-out {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEX19fX19fVVVVX09PSSNEQ7AAAAAnRSTlO0tU0x4YcAAAAuSURBVAjXY6hh+AOH/z8w/PoAIuEIwf3/Hwf+i118FS71/wlTq1YRp7IeRP4BADPMksSlXC3ZAAAAAElFTkSuQmCC);
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-out:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXu7u7u7u5VVVXu7u6LRCi7AAAAAnRSTlO0tU0x4YcAAAAuSURBVAjXY6hh+AOH/z8w/PoAIuEIwf3/Hwf+i118FS71/wlTq1YRp7IeRP4BADPMksSlXC3ZAAAAAElFTkSuQmCC);
}
.tui-chart .tui-chart-zoom-area > .tui-chart-zoom-btn.zoom-out:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXm5ubm5uZVVVXl5eUH/eecAAAAAnRSTlO0tU0x4YcAAAAuSURBVAjXY6hh+AOH/z8w/PoAIuEIwf3/Hwf+i118FS71/wlTq1YRp7IeRP4BADPMksSlXC3ZAAAAAElFTkSuQmCC);
}
.tui-chart .tui-chart-series-custom-event-area {
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
}
.tui-chart .tui-chart-series-custom-event-area.hide {
  display: none;
}
.tui-chart .tui-chart-series-custom-event-area.drag {
  cursor: move;
}
.tui-chart .tui-chart-series-custom-event-area .tui-chart-drag-selection {
  top: 10px;
  height: 100%;
  background-color: gray;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
  position: absolute;
  display: none;
}
.tui-chart .tui-chart-series-custom-event-area .tui-chart-drag-selection.show {
  display: block;
}
.tui-chart .tui-chart-series-custom-event-area .tui-chart-reset-zoom-btn {
  position: absolute;
  left: 20px;
  top: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAAAclJREFUSA21VT2LAjEQnZUFwZ9haWWvtcVhJwpiZSdY+AMEOxs7C8FCsBfERq/QVntbC/0B1iooQs43XkKym+wROAObTN7Mm49kltD1el1dLheBLyWE+JrP54QRAGEpukkBbTabrHSbsfp3YgpkSZOrQdcZToVypVtDVgr4lv4NxXQ6ZdJkMuGVZOG9Xo8PQO6dwZ2Kt7/4rJKKq+xIjKBXstlsaDAYGMwYAVoYYuRyOToejyzL6fM1eEUIguDbi4A6rEXLAm2rNyGMetHvoVwuU6VSMU1k82BFQy0WC9VU1WpVydLOSOl0OlGhUGCPsj31iKyQTKzb7VbU63VxPp/Zc6PREIfDwYgSO9b9fk/j8Zg/M/n3LkawGemYUYOucMneBO+UXJFduHdGLkcu/OMBYq1qywRXv16vCY2Gkc1mqVQqUT6ft5kbWOIdwPFwOKQwDKlWq6ku3u12NJvN6Pl8UqfTSQyUGKDVatH9fqfRaESZTMbI7Ha7UbvdpnQ67WxqEBLvAEeBgYz18Xg8qN/vM4QEut0uAbONxApAwLO6XC4VV75FAF7/MuPFYlHpo8KfAaIE333iEfk6s9l/PgCeElvk/8Bevlc/uNwgcfw7KQUAAAAASUVORK5CYII=);
  border-radius: 1px;
  font-size: 11px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart .tui-chart-series-custom-event-area .tui-chart-reset-zoom-btn:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAAGgrv1cAAAAM1BMVEXu7u7u7u5VVVVZWVlbW1tcXFxubm59fX2Ojo6enp6rq6u0tLTAwMDU1NTk5OTo6Oju7u5AkNcdAAAAAnRSTlO0tU0x4YcAAACCSURBVCjPtY/BDoQgDERhsCKI+P7/az0IJuqa9bBLQuibmbbBeXAkHLSro94fNeUkdk87acJBvEWeQJCPtDLL8L2nHf8u1kZLqUPMoA5WmaQGJVSGpfcUezX6I/zKKNEslptRFHKtOahcDFMFqLKLEZWBdZTG9bwjSQLm+Q//8A/6BjkPISqrVth/AAAAAElFTkSuQmCC);
}
.tui-chart .tui-chart-series-custom-event-area .tui-chart-reset-zoom-btn:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAAGgrv1cAAAAM1BMVEXm5ubm5uZVVVVYWFhaWlpcXFxsbGx6enqLi4uampqmpqaurq66urrNzc3c3Nzg4ODl5eUljSS/AAAAAnRSTlO0tU0x4YcAAACCSURBVCjPtY/BDoQgDERhsCKI+P7/az0IJuqa9bBLQuibmbbBeXAkHLSro94fNeUkdk87acJBvEWeQJCPtDLL8L2nHf8u1kZLqUPMoA5WmaQGJVSGpfcUezX6I/zKKNEslptRFHKtOahcDFMFqLKLEZWBdZTG9bwjSQLm+Q//8A/6BjkPISqrVth/AAAAAElFTkSuQmCC);
}
.tui-chart .tui-chart-legend-rect {
  margin-top: 2px;
  width: 10px;
  height: 10px;
}
.tui-chart .tui-chart-legend-rect.line {
  padding: 3px 0;
}
.tui-chart .tui-chart-chartExportMenu-area {
  position: absolute;
  font-family: Verdana;
  margin: 0;
  z-index: 1010;
}
.tui-chart .tui-chart-chartExportMenu-area .tui-chart-chartExportMenu-button {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEX19fX19fVVVVX09PSSNEQ7AAAAAnRSTlO0tU0x4YcAAAAwSURBVAjXY6hh+AOGb4Dw/weGfx9AJBb0/z8O/BeV/x4HjcDYqH/7ieUhU/Ug8g8ALeuSuXF73FYAAAAASUVORK5CYII=);
  background-color: #f4f4f4;
  border: 0px;
  font-size: 12px;
  padding: 3px 5px;
  margin: 0;
  cursor: pointer;
}
.tui-chart .tui-chart-chartExportMenu-area .tui-chart-chartExportMenu-button:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXu7u7u7u5VVVXu7u6LRCi7AAAAAnRSTlO0tU0x4YcAAAAwSURBVAjXY6hh+AOGb4Dw/weGfx9AJBb0/z8O/BeV/x4HjcDYqH/7ieUhU/Ug8g8ALeuSuXF73FYAAAAASUVORK5CYII=);
}
.tui-chart .tui-chart-chartExportMenu-area .tui-chart-chartExportMenu-button:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAAHqHuX9AAAADFBMVEXm5ubm5uZVVVXl5eUH/eecAAAAAnRSTlO0tU0x4YcAAAAwSURBVAjXY6hh+AOGb4Dw/weGfx9AJBb0/z8O/BeV/x4HjcDYqH/7ieUhU/Ug8g8ALeuSuXF73FYAAAAASUVORK5CYII=);
}
.tui-chart .tui-chart-chartExportMenu-area.menu-opened .tui-chart-chartExportMenu-button {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAAGXcA1uAAAAAXNSR0IArs4c6QAAAU5JREFUSA3NVTuOhDAMNSMEDeICnI6CK1AhGqi4Apegmb0RHABBAULaxZl1FJPPDEisNlJix37Pjp0IYJ7n5zRN3zg9XLquAxwPsf4uwkMG5mEbBiM4SoZK01T6rAyrg4WScdQc4zgCThqSsZcHOGn4pERRRKqQ1uRWB6MrG5lbsTlVjYCVYMeGYRBSrQwjaQRn+N15fw2nMnie93WKcKno013SCEVRyM6qOhnlYyIDShOQ/MaisywT/rZtCSelRjhGr+taglHRakAjgo5AtOPQMrzM9tWYwQ63HMlFOH0kVzCT73QJpiAu2/9IsK6r8ZA2uwo2Pm0CLMsCVVVB3/eQJAnkeQ5xHIsvcNM00l6WJQRBQDQmP7pk/PBRQN/3Yds2lpBFPGxuvwNnBX/WIrxMU4/xAGEYHprCt84KOPTa7vY7eOCv5NrZ3rP22M8fyPG11kibO+MAAAAASUVORK5CYII=');
}
.tui-chart .tui-chart-chartExportMenu-area.menu-opened .tui-chart-chartExportMenu-button:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAFlXhBdAAAAIVBMVEXu7u7u7u5VVVVZWVlvb2+hoaHV1dXh4eHn5+fo6Oju7u75trgQAAAAAnRSTlO0tU0x4YcAAABlSURBVBjTY5BawLA0AIRWLYChVasYVi1aBaFhuByI06A0RAyFKkqqQqJWoStZiE0QRHktAVMuLmBqsRUyDyqHVR8CEOCsBCMIZ4WT+3InT5jMcicj91XYOKjKgLpXELBHCsFeAACm5rR2c72cZwAAAABJRU5ErkJggg==);
}
.tui-chart .tui-chart-chartExportMenu-area.menu-opened .tui-chart-chartExportMenu-button:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAFlXhBdAAAAIVBMVEXm5ubm5uZVVVVZWVltbW2dnZ3Ozs7Z2dnf39/g4ODl5eV33AFxAAAAAnRSTlO0tU0x4YcAAABlSURBVBjTY5BawLA0AIRWLYChVasYVi1aBaFhuByI06A0RAyFKkqqQqJWoStZiE0QRHktAVMuLmBqsRUyDyqHVR8CEOCsBCMIZ4WT+3InT5jMcicj91XYOKjKgLpXELBHCsFeAACm5rR2c72cZwAAAABJRU5ErkJggg==);
}
.tui-chart .tui-chart-chartExportMenu-area ul {
  display: none;
  position: absolute;
  top: 28px;
  right: 0;
  width: 150px;
  background: #fff;
  border: 1px solid #bab9ba;
  font-size: 11px;
  padding: 0;
  margin: 0;
}
.tui-chart .tui-chart-chartExportMenu-area ul > li {
  margin: 0;
  border-collapse: collapse;
  text-align: center;
  list-style-type: none;
}
.tui-chart .tui-chart-chartExportMenu-area ul > li.tui-chart-chartExportMenu-head {
  padding: 10px;
  text-align: left;
  background-color: #f3f3f3;
  margin: 1px 1px 0 0;
}
.tui-chart .tui-chart-chartExportMenu-area ul > li.tui-chart-chartExportMenu-body {
  display: inline-block;
  width: 100%;
  padding: 7px 0;
}
.tui-chart .tui-chart-chartExportMenu-area ul > li.tui-chart-chartExportMenu-body > li {
  padding: 12px 3px;
  line-height: 1;
  cursor: pointer;
  width: 50%;
  float: left;
}
.tui-chart .tui-chart-chartExportMenu-area ul > li.tui-chart-chartExportMenu-body > li:hover {
  font-weight: bold;
}
.tui-chart .tui-chart-legend-area {
  z-index: 400;
  position: absolute;
  padding: 10px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend {
  clear: both;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend > div {
  float: left;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend .tui-chart-legend-checkbox-area {
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend .tui-chart-legend-checkbox-area input {
  left: 2px;
  top: 2px;
  *left: -2px;
  *top: -2px;
  position: absolute;
  padding: 0;
  margin: 0;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend .tui-chart-legend-label {
  padding: 2px 0 2px 4px;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  line-height: 1;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend.unselected .tui-chart-legend-rect {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend.unselected .tui-chart-legend-label {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend-tick-area {
  position: absolute;
  left: 10px;
  top: 10px;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend-tick-area .tui-chart-map-legend-tick {
  width: 15px;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  left: 0px;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend-tick-area .tui-chart-map-legend-tick-label {
  position: absolute;
  left: 30px;
  text-align: left;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend-tick-area.horizontal .tui-chart-map-legend-tick {
  top: 0px;
  width: 1px;
  height: 15px;
}
.tui-chart .tui-chart-legend-area .tui-chart-legend-tick-area.horizontal .tui-chart-map-legend-tick-label {
  top: 30px;
}
.tui-chart .tui-chart-legend-area .tui-chart-map-legend-wedge {
  position: absolute;
  width: 19px;
  height: 4px;
  border-left: 2px solid #777;
  border-right: 2px solid #777;
  left: 8px;
  top: 30px;
  display: none;
}
.tui-chart .tui-chart-legend-area .tui-chart-map-legend-wedge.show {
  display: block;
}
.tui-chart .tui-chart-legend-area.horizontal {
  padding-left: 0;
  padding-right: 0;
}
.tui-chart .tui-chart-legend-area.horizontal .tui-chart-legend {
  clear: none;
  float: left;
  white-space: nowrap;
}
.tui-chart .tui-chart-circle-legend-area {
  position: absolute;
  z-index: 400;
}
.tui-chart .tui-chart-circle-legend-area .tui-chart-circle-legend-label-area {
  position: absolute;
  left: 0;
  top: 0;
}
.tui-chart .tui-chart-circle-legend-area .tui-chart-circle-legend-label-area .tui-chart-circle-legend-label {
  position: absolute;
  font-size: 9px;
  white-space: nowrap;
  text-shadow: #fff 0px 0px 3px;
}
.tui-chart .tui-chart-tooltip-area {
  position: absolute;
  z-index: 500;
}
.tui-chart .tui-chart-tooltip-area * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip {
  z-index: 100;
  position: absolute;
  display: none;
  user-select: none;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip.show {
  display: block;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  background-color: #5f5f5f;
  background-color: rgba(85, 85, 85, 0.95);
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > * {
  text-align: left;
  white-space: nowrap;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-head {
  padding: 8px 15px;
  border-bottom: 1px solid #565656;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > table tr {
  padding-bottom: 5px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-body {
  border-bottom: 1px solid #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 11px 15px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-body span {
  display: inline-block;
  height: 10px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-body span.tui-chart-legend-rect {
  width: 10px;
  margin-right: 8px;
  vertical-align: text-top;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-body span.tui-chart-legend-rect.line {
  padding: 0;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip > .tui-chart-tooltip-body .tui-chart-tooltip-value {
  padding-left: 44px;
  text-align: right;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > * {
  text-align: left;
  position: relative;
  padding-left: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > table {
  border-spacing: 0;
  border-collapse: separate;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > table tr {
  padding-bottom: 5px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > table tr:last-child {
  padding-bottom: 0;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > .tui-chart-tooltip-body {
  padding: 11px 15px;
  font-size: 12px;
  font-weight: lighter;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > .tui-chart-tooltip-body > span {
  display: inline-block;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > .tui-chart-tooltip-body .tui-chart-tooltip-value {
  padding-left: 44px;
  text-align: right;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip .tui-chart-legend-rect {
  position: relative;
  left: 0px;
  top: -2px;
  margin-right: 5px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > .tui-chart-tooltip-head {
  padding: 8px 15px;
  text-align: left;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip.tui-chart-group-tooltip > .tui-chart-tooltip-type {
  padding-left: 7px;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-tooltip .tui-chart-default-tooltip .hide {
  display: none;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-group-tooltip-sector {
  z-index: 50;
  position: absolute;
  background-color: #aaa;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.3;
  display: none;
}
.tui-chart .tui-chart-tooltip-area .tui-chart-group-tooltip-sector.show {
  display: block;
}
.tui-chart.tui-map-chart .tui-chart-series-area {
  overflow: hidden;
}
.tui-chart.tui-map-chart .tui-chart-tooltip-area .tui-chart-default-tooltip > :first-child {
  font-weight: normal;
}
.tui-chart.tui-treemap-chart .tui-chart-reset-zoom-btn {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAFlXhBdAAAAMFBMVEX19fX19fVVVVVbW1tgYGBkZGRvb2+CgoKNjY2QkJC4uLi5ubnZ2dnj4+Pu7u709PQ6Nl8lAAAAAnRSTlO0tU0x4YcAAABxSURBVBjTY5D/wPDtA8N/GPoEJP//Z/j/Ph5CI+O/QKwB5yNTv5WU7oN53/sx5EDgIzZBIPVHH0x9VlIBC87/BJG7BKaUYv//x6EPCRDgvETiLFZSUu2Fcf4k/f9b1I+k51I8jPNJSUnlPgn2yCPYHwBOuQ5rod0HXAAAAABJRU5ErkJggg==);
}
.tui-chart.tui-treemap-chart .tui-chart-reset-zoom-btn:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAFlXhBdAAAAMFBMVEXu7u7u7u5VVVVbW1tfX19kZGRubm6BgYGLi4uOjo60tLS1tbXU1NTd3d3o6Oju7u6bzfGZAAAAAnRSTlO0tU0x4YcAAABxSURBVBjTY5D/wPDtA8N/GPoEJP//Z/j/Ph5CI+O/QKwB5yNTv5WU7oN53/sx5EDgIzZBIPVHH0x9VlIBC87/BJG7BKaUYv//x6EPCRDgvETiLFZSUu2Fcf4k/f9b1I+k51I8jPNJSUnlPgn2yCPYHwBOuQ5rod0HXAAAAABJRU5ErkJggg==);
}
.tui-chart.tui-treemap-chart .tui-chart-reset-zoom-btn:active {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAFlXhBdAAAAMFBMVEXm5ubm5uZVVVVaWlpfX19jY2NsbGx+fn6Hh4eLi4uurq6vr6/Nzc3V1dXg4ODl5eWoWcyYAAAAAnRSTlO0tU0x4YcAAABxSURBVBjTY5D/wPDtA8N/GPoEJP//Z/j/Ph5CI+O/QKwB5yNTv5WU7oN53/sx5EDgIzZBIPVHH0x9VlIBC87/BJG7BKaUYv//x6EPCRDgvETiLFZSUu2Fcf4k/f9b1I+k51I8jPNJSUnlPgn2yCPYHwBOuQ5rod0HXAAAAABJRU5ErkJggg==);
}
.tui-chart-size-check-element {
  clear: both;
  position: absolute;
  word-wrap: break-word;
  word-break: keep-all;
  top: 100000px;
  left: 100000px;
  width: 1000px;
  height: 100px;
  padding: 0;
  line-height: 1;
}
.tui-chart-size-check-element > span {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
}
:root .tui-chart .tui-chart-axis-area.vertical .tui-chart-title-area {
  filter: none \0;
}
:root .tui-chart .tui-chart-axis-area.horizontal .tui-chart-label.tui-chart-xaxis-rotation25 span,
:root .tui-chart .tui-chart-axis-area.horizontal .tui-chart-label.tui-chart-xaxis-rotation45 span,
:root .tui-chart .tui-chart-axis-area.horizontal .tui-chart-label.tui-chart-xaxis-rotation65 span,
:root .tui-chart .tui-chart-axis-area.horizontal .tui-chart-label.tui-chart-xaxis-rotation85 span {
  filter: none \0;
}
.box-shadow {
  filter: shadow(0 4px 4px);
}
